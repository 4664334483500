import React from "react";
import DevelopmentPageTemplate from "../../templates/development-page";
import { Alert } from "react-bootstrap";
import "../../threeplay.scss";

export default class Index extends React.Component {
  render() {
    var overview = (
      <>
        <p>
          Spinners are animated components that indicate to the user that data is either saving or loading. Spinners should be triggered and tied to actions users perform, and should result in an error or success state after the loader is hidden.
        </p>
      </>
    );

    var codeString = `  
    // Font Awesome Loaders
    <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
    <span className="sr-only">Loading...</span>
    <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
    <span className="sr-only">Loading...</span>
    <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
    <span className="sr-only">Loading...</span>

    // Custom 3Play Loader
    <DarkOverlay>
      <div className="light-loader">
        <div className="loader">
          <div className="loading-logo">
            <span></span>
          </div>
          <div className="loading-text" data-loading-message={props.message}></div>
        </div>
      </div>
    </DarkOverlay>

    // CSS
    #ajax-loader
      display: none
      z-index: 1000
      .loader
        width: 200px
        height: 80px
        position: absolute
        top: 0
        right: 0
        left: 0
        bottom: 0
        margin: auto

      .loading-text
        text-align: center
        margin: 20px 0
        color: #000
        font-size: 16px
        &::before
          content: "Loading your data "
        &::after
          overflow: hidden
          display: inline-block
          vertical-align: bottom
          -webkit-animation: ellipsis steps(4, end) 1.3s infinite
          animation: ellipsis steps(4, end) 1.3s infinite
          width: 0
          margin-right: 20px

      @keyframes ellipsis
        to
          width: 20px
          margin-right: 0


      .loading-logo
        display: block
        width: 80px
        height: 80px
        vertical-align: middle
        pointer-events: none
        color: #00B2EE
        text-align: center
        margin: 0 auto
        -webkit-transform: rotateZ(5deg)
        transform: rotateZ(5deg)
        border-radius: 100%
        span
          display: block
          width: 80px
          height: 80px
          vertical-align: middle
          pointer-events: none
          color: #00B2EE
          text-align: center
          margin: 0 auto
          -webkit-transform: rotateZ(5deg)
          transform: rotateZ(5deg)
          border-radius: 100%
        &:before, &:after, span:before
          content: ''
          display: block
          position: absolute
          top: 0
          left: 0
          width: inherit
          height: inherit
          border-radius: 100%
          -webkit-animation: 1s spin ease-in-out infinite
          animation: 1s spin ease-in-out infinite
        &:before
          -webkit-transform: rotate3d(0.8, 0.5, 0, 60deg)
          transform: rotate3d(0.8, 0.5, 0, 60deg)
        &:after
          -webkit-transform: rotate3d(0, 1, 0, 60deg)
          transform: rotate3d(0, 1, 0, 60deg)
          -webkit-animation-delay: .333s
          animation-delay: .333s
        span:before
          -webkit-transform: rotate3d(0.5, 1, 0.6, 175deg)
          transform: rotate3d(0.5, 1, 0.6, 175deg)
          -webkit-animation-delay: .666s
          animation-delay: .666s

      @-webkit-keyframes spin
        0%,
        100%
          box-shadow: .2em 0px 0 0px currentcolor

        12%
          box-shadow: .2em .2em 0 0 currentcolor

        25%
          box-shadow: 0 .2em 0 0px currentcolor

        37%
          box-shadow: -.2em .2em 0 0 currentcolor

        50%
          box-shadow: -.2em 0 0 0 currentcolor

        62%
          box-shadow: -.2em -.2em 0 0 currentcolor

        75%
          box-shadow: 0px -.2em 0 0 currentcolor

        87%
          box-shadow: .2em -.2em 0 0 currentcolor

      @keyframes spin
        0%
          box-shadow: .2em 0px 2px 1px currentcolor

        12%
          box-shadow: .2em .2em 2px 1px currentcolor

        25%
          box-shadow: 0 .2em 2px 1px currentcolor

        37%
          box-shadow: -.2em .2em 2px 1px currentcolor

        50%
          box-shadow: -.2em 2m 2px 1px currentcolor

        62%
          box-shadow: -.2em -.2em 2px 1px currentcolor

        75%
          box-shadow: 0px -.2em 2px 1px currentcolor

        87%
          box-shadow: .2em -.2em 2px 1px currentcolor

        100%
          box-shadow: .2em 0px 2px 1px currentcolor

      // Overlay for the loader
      .overlay
      opacity: 0.2
    `;

    var code = (
      <>
      <br />
      <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
      <span className="sr-only">Loading...</span>
      <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
      <span className="sr-only">Loading...</span>
      <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
      <span className="sr-only">Loading...</span>
      <br />
      <br />
      </>
    );

    var codes = [
      {
        code: code,
        codeLanguage: "html",
        codeString: codeString
      }
    ]

    var accessibilitySection = (
      <>
        <h5>Animations should not be too quick.</h5>
        <p>Too much animation at once can be overwhelming and jarring for users. For the Font Awesome spinner, use the default fa-spin class to animate the icon. For the 3Play loader, do not overwrite the default animation behavior.</p>
      </>
    );

    return (
      <DevelopmentPageTemplate
        title="Spinner"
        overview={overview}
        codes={codes}
        accessibilitySection={accessibilitySection}
      />
    );
  }
}
